import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModalType, WindowService } from '@movecloser/front-core';
import { Fragment } from 'vue-fragment';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { PaywallAction, PaywallMode, PaywallServiceType } from '@service/paywall';
import { SiteLogoVariant, SiteServiceType } from '@service/site';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let Paywall = class Paywall extends Vue {
    constructor() {
        super(...arguments);
        this.isChecking = true;
        this.isLoading = false;
        this.result = null;
    }
    get isLimitExceeded() {
        var _a, _b;
        return [PaywallMode.LimitReached, PaywallMode.SubscriptionRequired].includes(this.mode) ||
            (this.result !== null && ((_a = this.result) === null || _a === void 0 ? void 0 : _a.status.used) >= ((_b = this.result) === null || _b === void 0 ? void 0 : _b.status.limit));
    }
    get isLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    get isPaymentError() {
        return this.$route.query.error === '501';
    }
    get isRestricted() {
        var _a;
        return ((_a = this.result) === null || _a === void 0 ? void 0 : _a.status.isRestricted) || false;
    }
    get isSubscriptionRequired() {
        var _a;
        return this.mode === PaywallMode.SubscriptionRequired ||
            ((_a = this.result) === null || _a === void 0 ? void 0 : _a.status.requiredAction) === PaywallAction.Subscription;
    }
    get logotypeAlt() {
        return `${this.$t('components.navbar.logotype-of-the-xyz-site')} ${this.siteService.getActiveSite().name}`;
    }
    get logotypeSrc() {
        const site = this.siteService.getActiveSite();
        return site.logo[SiteLogoVariant.Light] || '';
    }
    async checkAccess() {
        try {
            const result = await this.paywallService.checkAccess(this.$route.path, this.page.contentId);
            if (!result.status.isRestricted) {
                this.unlock();
                return;
            }
            this.result = result;
            this.isChecking = false;
        }
        catch (e) {
            this.$logger(e, 'warn');
        }
    }
    login() {
        this.modalConnector.open(EModal.LogIn, { onSuccess: this.showSingleAccessModal });
    }
    register() {
        this.modalConnector.open(EModal.Welcome);
    }
    showDetails() {
        this.modalConnector.open(EModal.BuySubscription, {}, { size: 'large' });
    }
    showSingleAccessModal() {
        if (this.isLoggedIn) {
            this.modalConnector.open(EModal.BuySingleAccess, {
                pageId: this.page.contentId.toString(),
                price: this.getPrice(),
                pageTitle: this.page.title
            }, { size: 'large' });
        }
        else {
            this.login();
        }
    }
    unlock() {
        this.isLoading = true;
        this.$emit('unlock');
    }
    getPrice() {
        var _a;
        const prices = this.siteService.getArticlePrices();
        if (!prices) {
            return '';
        }
        const premiumType = this.page.options.paywall ? 'standard' : 'free';
        if (prices[premiumType]) {
            return (_a = prices[premiumType].price) !== null && _a !== void 0 ? _a : '';
        }
        return '';
    }
    onUserLoggedIn() {
        this.checkAccess();
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    })
], Paywall.prototype, "mode", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], Paywall.prototype, "page", void 0);
__decorate([
    Inject(ModalType)
], Paywall.prototype, "modalConnector", void 0);
__decorate([
    Inject(PaywallServiceType)
], Paywall.prototype, "paywallService", void 0);
__decorate([
    Inject(SiteServiceType)
], Paywall.prototype, "siteService", void 0);
__decorate([
    Watch('isLoggedIn')
], Paywall.prototype, "onUserLoggedIn", null);
Paywall = __decorate([
    Component({
        name: 'Paywall',
        components: { Fragment },
        created() {
            if (WindowService.isDefined) {
                this.checkAccess();
            }
        },
        mounted() {
            if (this.isPaymentError) {
                this.modalConnector.open(EModal.PaymentError, { onTryAgain: this.showSingleAccessModal });
            }
        }
    })
], Paywall);
export { Paywall };
export default Paywall;
