import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
const MONTHS = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
/**
 * @author Sebastian Dziechciarz <sebastian.dziechciarz@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let CommentMeta = class CommentMeta extends Vue {
    /**
     * The date and time when comment has been published.
     */
    get createdAt() {
        return `${this.publicationDay} ${this.publicationMonth} (${this.publicationHour}:${this.publicationMinutes})`;
    }
    /**
     * The fallback name for comment creator.
     */
    get displayName() {
        return (typeof this.name !== 'undefined' && this.name !== null) ? this.name : this.$t('_.user').toString();
    }
    /**
       * Value for the `<time>`s `[datetime]` attribute .
       */
    get dateTime() {
        return this.parsed.toISOString();
    }
    get parsed() {
        return new Date(this.publicationDate.replace(' ', 'T'));
    }
    /**
       * The comment's publication day.
       */
    get publicationDay() {
        return this.parsed.getDate();
    }
    /**
       * The comment's publication month.
       */
    get publicationMonth() {
        return MONTHS[this.parsed.getMonth()];
    }
    /**
       * The comment's publication hour.
       */
    get publicationHour() {
        const hours = this.parsed.getHours();
        if (hours < 10) {
            return `0${hours}`;
        }
        return `${hours}`;
    }
    /**
       * The comment's publication minute(s).
       */
    get publicationMinutes() {
        const minutes = this.parsed.getMinutes();
        if (minutes < 10) {
            return `0${minutes}`;
        }
        return `${minutes}`;
    }
};
__decorate([
    Prop({ type: String, required: false, default: null })
], CommentMeta.prototype, "name", void 0);
__decorate([
    Prop({ type: String, required: true })
], CommentMeta.prototype, "publicationDate", void 0);
CommentMeta = __decorate([
    Component({ name: 'CommentMeta' })
], CommentMeta);
export { CommentMeta };
export default CommentMeta;
