import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LayoutContainerType, LayoutSlot, ModuleDriver } from '@d24/modules/front';
import { RelatedServiceType } from '@service/related';
import { PaywallMode } from '@service/paywall';
import { Content } from '../../models/content';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
/**
 * Default slots settings.
 */
const slotDefaults = (mainTag) => ({
    [LayoutSlot.Top]: {
        tag: 'div'
    },
    [LayoutSlot.Main]: {
        tag: mainTag
    },
    [LayoutSlot.Left]: {
        tag: 'aside',
        columns: 2
    },
    [LayoutSlot.Right]: {
        tag: 'aside',
        columns: 2
    },
    [LayoutSlot.Comments]: {
        tag: 'div'
    },
    [LayoutSlot.Bottom]: {
        tag: 'div'
    }
});
/**
 * Abstract component for pages using Layout.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let WithLayout = class WithLayout extends Vue {
    constructor() {
        super(...arguments);
        this.content = null;
        this.mainContentTag = 'article';
        this.relatedService = null;
        this.slots = {};
        this.LayoutSlot = LayoutSlot;
    }
    getContainerClass(container) {
        if (typeof this.result === 'undefined') {
            this.$logger('WithLayout.getContainerClass(): [this.result] is [undefined]! Falling back to the default value ([container])', 'warn');
            return 'container my-6';
        }
        if (typeof this.result.content.containers[container] === 'undefined') {
            this.$logger(`WithLayout.getContainerClass(): [this.result.content.containers[${container}]] is [undefined]! Falling back to the default value ([container])`, 'warn');
            return 'container my-6';
        }
        if (!Object.values(LayoutContainerType).includes(this.result.content.containers[container])) {
            this.$logger(`WithLayout.getContainerClass(): [this.result.content.containers[${container}]] has value of [${this.result.content.containers[container]}] which is NOT supported! Falling back to the default value ([container])`, 'warn');
            return 'container my-6';
        }
        switch (this.result.content.containers[container]) {
            case LayoutContainerType.FullWidth: {
                return 'container-fluid px-0';
            }
            case LayoutContainerType.Boxed:
            default: {
                return 'container my-6';
            }
        }
    }
    get canonicalUrl() {
        const domain = this.siteService.getActiveSite().address.slice(0, -1);
        let params = '';
        if (this.$route.query.page) {
            params = '?' + new URLSearchParams({ page: this.$route.query.page }).toString();
        }
        return (!!this.result.canonical && this.result.canonical.length > 0) ? this.result.canonical : (domain + this.$route.path + params);
    }
    get hasPaywall() {
        return this.result.paywall !== PaywallMode.AccessGranted;
    }
    get siteMetadata() {
        const breadcrumbsMetadata = this.content ? this.buildBreadcrumbsMetadata(this.content) : [];
        return {
            '@context': 'https://schema.org',
            '@graph': [
                {
                    '@type': 'BreadcrumbList',
                    itemListElement: breadcrumbsMetadata
                }
            ]
        };
    }
    buildBreadcrumbsMetadata(node) {
        var _a;
        const result = this.buildParentBreadcrumbsMetadata((_a = node.parent) !== null && _a !== void 0 ? _a : null);
        return [
            ...result.breadcrumbs,
            {
                '@type': 'ListItem',
                position: result.position + 1,
                name: node.title
            }
        ];
    }
    buildParentBreadcrumbsMetadata(node) {
        if (!node) {
            return {
                breadcrumbs: [],
                position: 0,
                urlPrefix: this.siteService.getActiveSite().address
            };
        }
        const result = this.buildParentBreadcrumbsMetadata(node.parent);
        const position = result.position + 1;
        const item = (result.urlPrefix + '/' + node.slug)
            .replace(/\/(\/)*/g, '/')
            .replace(/(http.?:\/)/g, '$1/'); // replace double slash, but not in protocol
        return {
            breadcrumbs: [
                ...result.breadcrumbs,
                {
                    '@type': 'ListItem',
                    position,
                    name: node.name,
                    item
                }
            ],
            position,
            urlPrefix: item
        };
    }
    buildContent() {
        this.composeContent();
        if (!this.content || !this.content.slots) {
            this.$logger('[Content]: Bootstrapping without loaded data', 'warn');
            return;
        }
        this.composeSlots(slotDefaults(this.mainContentTag));
    }
    composeContentProperties() {
        return {
            ...this.result.content.properties,
            canonicalUrl: this.canonicalUrl,
            hasPaywall: this.hasPaywall,
            paywall: this.result.content.contentProperties.paywall,
            site: this.siteService.getActiveSite(),
            videoAdsTag: this.siteService.getAdsConfig().videoAdsTag
        };
    }
    composeContent() {
        this.$logger('🏷 WithLayout.composeContent()');
        this.content = Content.hydrate({
            ...this.result.content,
            properties: this.composeContentProperties()
        });
        this.$logger(`Loaded content - ${this.content.title}`);
        if (typeof this.$container === 'undefined') {
            throw new Error('WithLayout.composeContent(): [this.$container] is undefined!');
        }
        this.relatedService = this.$container.get(RelatedServiceType);
        this.relatedService.storeRelated({ ...this.result.related });
        this.$logger(`🏷 Created new RelatedService instance with data: ${JSON.stringify(this.result.related)}`, 'debug', false);
    }
    composeSlots(slotDefaults) {
        let slotName;
        const composedSlots = {};
        if (typeof this.content === 'undefined' || this.content === null) {
            throw new Error('WithLayout.composeSlots(): [this.content] is undefined!');
        }
        for (slotName in this.content.slots) {
            if (!Object.prototype.hasOwnProperty.call(this.content.slots, slotName)) {
                this.$logger(`WithLayout.composeSlots(): [this.content.slots] does NOT have its own property with name [${slotName}]!`, 'error');
                continue;
            }
            const slotModules = this.content.slots[slotName];
            if (slotModules.length && slotDefaults[slotName]) {
                composedSlots[slotName] = {
                    ...slotDefaults[slotName],
                    modules: slotModules
                };
            }
        }
        this.slots = composedSlots;
    }
    composeTargeting() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const urlPathName = document.location.pathname;
        const urlPathParts = urlPathName.split('/');
        const urlParams = (urlPathName && urlPathParts.length)
            ? {
                url: urlPathName.slice(-40),
                category: urlPathParts[1].slice(-40)
            }
            : {};
        const contentModules = Object.values(this.slots).reduce((acc, slot) => {
            if (!(slot === null || slot === void 0 ? void 0 : slot.modules)) {
                return acc;
            }
            if (Array.isArray(slot.modules)) {
                return [...acc, ...slot.modules];
            }
            return [...acc, slot.modules];
        }, []);
        const adModules = contentModules.filter(module => module.driver === ModuleDriver.AdBanner);
        const adUnitName = adModules.reduce((acc, module) => {
            if (!module.content.adUnitFullPath) {
                console.warn('AdUnit without fullPath!', module.content.name, module);
                return acc;
            }
            const adUnit = `/${module.content.networkCode}/${module.content.adUnitFullPath}`;
            return {
                ...acc,
                [adUnit]: {
                    ...module.content.targeting,
                    ...urlParams
                }
            };
        }, {});
        return {
            ...(((_b = (_a = this.content) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.adsAdXAdSense) ? {} : { policyAdSense: 'yes' }),
            ...(((_d = (_c = this.content) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 : _d.adsAdX) ? {} : { policy: 'yes' }),
            ...(((_f = (_e = this.content) === null || _e === void 0 ? void 0 : _e.options) === null || _f === void 0 ? void 0 : _f.ads) ? {} : { noAds: 'yes' }),
            ...(this.isPremium ? { premium: 'yes' } : {}),
            ...(((_h = (_g = this.content) === null || _g === void 0 ? void 0 : _g.options) === null || _h === void 0 ? void 0 : _h.sponsored) ? { sponsored: 'yes' } : {}),
            adUnitName
        };
    }
    setAdTargeting() {
        this.$emit('setAdTargeting', this.composeTargeting());
    }
    onResultChange() {
        this.buildContent();
    }
    onPremiumChange() {
        this.setAdTargeting();
    }
};
__decorate([
    Prop({
        type: Object,
        required: true
    })
], WithLayout.prototype, "result", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false,
        default: false
    })
], WithLayout.prototype, "isPremium", void 0);
__decorate([
    Inject(SiteServiceType)
], WithLayout.prototype, "siteService", void 0);
__decorate([
    Watch('result', { deep: true })
], WithLayout.prototype, "onResultChange", null);
__decorate([
    Watch('isPremium')
], WithLayout.prototype, "onPremiumChange", null);
WithLayout = __decorate([
    Component({
        name: 'WithLayout',
        created() {
            this.buildContent();
        },
        mounted() {
            this.setAdTargeting();
        }
    })
], WithLayout);
export { WithLayout };
export default WithLayout;
