import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * Component capable of rendering a list of article tags.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let TagsList = class TagsList extends Vue {
    constructor() {
        super(...arguments);
        this.mobileTagsLimit = 4;
        this.resolvedTags = [];
        this.showAll = false;
    }
    get showAllLabel() {
        return this.showAll ? 'Ukryj tagi' : 'Pokaż wszystkie';
    }
    resolveTagsModifiers(index) {
        if (this.showAll || index < this.mobileTagsLimit) {
            return '';
        }
        return 'd-none d-sm-inline-block';
    }
    toggleShowAll() {
        this.showAll = !this.showAll;
    }
    async resolveTags() {
        const resolved = [];
        for (const tag of this.tags) {
            try {
                resolved.push(await this.relatedService.resolve(tag));
            }
            catch (e) {
                this.$logger(e, 'warn');
            }
        }
        this.resolvedTags = resolved;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], TagsList.prototype, "relatedService", void 0);
__decorate([
    Prop({ type: Array, required: true, default: () => [] })
], TagsList.prototype, "tags", void 0);
TagsList = __decorate([
    Component({
        name: 'TagsList',
        async prefetch() {
            await this.resolveTags();
        }
    })
], TagsList);
export { TagsList };
export default TagsList;
