import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Sebastian Dziechciarz <sebastian.dziechciarz@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>, Olga Milczek
 */
let CommentAvatar = class CommentAvatar extends Vue {
    get fallback() {
        return require('@/assets/images/placeholders/avatar.svg');
    }
    get image() {
        if (!this.avatar) {
            return { src: '' };
        }
        return this.avatar;
    }
};
__decorate([
    Prop({ type: Object, required: false })
], CommentAvatar.prototype, "avatar", void 0);
CommentAvatar = __decorate([
    Component({ name: 'CommentAvatar' })
], CommentAvatar);
export { CommentAvatar };
export default CommentAvatar;
