import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EModal } from '@contract/modal';
import { ModalType, ResourceActionFailed } from '@movecloser/front-core';
import { Alert } from '@component/Alert';
import { Inject } from '@plugin/inversify';
import { UserRepositoryType } from '@profile/contracts';
/**
 * @author Sebastian Dziechciarz <sebastian.dziechciarz@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let CommentForm = class CommentForm extends Vue {
    constructor() {
        super(...arguments);
        this.message = '';
        this.hasConfirmedNickname = false;
        this.hasActiveAccount = false;
    }
    get hasNicknameSet() {
        var _a;
        return !!((_a = this.user) === null || _a === void 0 ? void 0 : _a.nickname) && this.user.nickname.length > 0;
    }
    /**
     * Handles alert close.
     */
    onAlertClose() {
        this.message = '';
        this.$emit('alertClose');
    }
    onLogIn() {
        this.modalConnector.open(EModal.LogIn);
    }
    /**
     * Handles `@submit` event on the `<form>`.
     */
    onSubmit() {
        if (!this.hasActiveAccount) {
            this.modalConnector.open(EModal.NotActiveAccountModal, {
                content: 'Aby dodać komentarz aktywuj swoje konto.'
            });
            return;
        }
        if (!this.hasConfirmedNickname) {
            this.modalConnector.open(EModal.NoNicknameSet, {
                onClose: this.onNicknameModalClose
            });
        }
        else {
            this.$emit('submit', this.message);
        }
    }
    async onNicknameModalClose() {
        try {
            await this.userRepository.update({
                nicknameConfirmed: true
            });
            this.hasConfirmedNickname = true;
            this.modalConnector.close();
        }
        catch (e) {
            if (e instanceof ResourceActionFailed) {
                this.$logger(`[CommentForm]: Error while updating nicknameConfirmed state, ${e.payload}`, 'error');
            }
            else {
                this.$logger(`[CommentForm]: Error while updating nicknameConfirmed state, ${e.message}`, 'error');
            }
        }
    }
    onMessageType(message) {
        if (message && this.errors.validationMessage.length > 0) {
            this.$emit('resetErrors');
        }
    }
    onUser(user) {
        var _a, _b, _c, _d;
        if (user) {
            this.hasConfirmedNickname = (_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.nicknameConfirmed) !== null && _b !== void 0 ? _b : false;
            this.hasActiveAccount = (_d = (_c = this.user) === null || _c === void 0 ? void 0 : _c.isActive) !== null && _d !== void 0 ? _d : false;
        }
    }
};
__decorate([
    Prop({ type: Object, required: false, default: null })
], CommentForm.prototype, "alert", void 0);
__decorate([
    Prop({ type: Object, required: false, default: () => { return {}; } })
], CommentForm.prototype, "errors", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], CommentForm.prototype, "hasErrors", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], CommentForm.prototype, "isAuthenticated", void 0);
__decorate([
    Prop({ type: Boolean, required: true, default: false })
], CommentForm.prototype, "lockCommenting", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], CommentForm.prototype, "user", void 0);
__decorate([
    Inject(ModalType)
], CommentForm.prototype, "modalConnector", void 0);
__decorate([
    Inject(UserRepositoryType)
], CommentForm.prototype, "userRepository", void 0);
__decorate([
    Watch('message', { deep: false })
], CommentForm.prototype, "onMessageType", null);
__decorate([
    Watch('user')
], CommentForm.prototype, "onUser", null);
CommentForm = __decorate([
    Component({
        name: 'CommentForm',
        components: { Alert },
        mounted() {
            var _a, _b, _c, _d;
            this.hasConfirmedNickname = (_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.nicknameConfirmed) !== null && _b !== void 0 ? _b : false;
            this.hasActiveAccount = (_d = (_c = this.user) === null || _c === void 0 ? void 0 : _c.isActive) !== null && _d !== void 0 ? _d : false;
        }
    })
], CommentForm);
export { CommentForm };
export default CommentForm;
