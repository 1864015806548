import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * @author Sebastian Dziechciarz <sebastian.dziechciarz@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let CommentActions = class CommentActions extends Vue {
};
CommentActions = __decorate([
    Component({ name: 'CommentActions' })
], CommentActions);
export { CommentActions };
export default CommentActions;
