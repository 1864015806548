// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { AuthServiceType, EventbusType } from '@movecloser/front-core';
import { Component, Vue } from 'vue-property-decorator';
import { AlertTheme } from '@component/Alert';
import { ConnectorErrors } from '@exception/connector-errors';
import { Inject } from '@plugin/inversify';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let CommentAbstract = class CommentAbstract extends Vue {
    constructor() {
        super(...arguments);
        this.alert = null;
        this.errors = { message: '', validationMessage: [] };
        this.hasErrors = false;
    }
    /**
     * Handles `@click` event on closing alert - reset alert and errors.
     */
    onAlertClose() {
        this.alert = null;
        this.hasErrors = false;
    }
    resetErrors() {
        if (this.errors.message || this.errors.validationMessage.length > 0) {
            this.onAlertClose();
            this.errors = { message: '', validationMessage: [] };
        }
    }
    deleteDuplicates(commentList) {
        const usedId = [];
        return commentList.filter(comment => {
            if (usedId.includes(comment.id)) {
                return false;
            }
            usedId.push(comment.id);
            return true;
        });
    }
    onErrors(error) {
        this.alert = {
            theme: error.status === ConnectorErrors.Validation ? AlertTheme.Warning : AlertTheme.Danger,
            useDefaultIcon: false,
            dismissible: true,
            autoTimeout: 10000
        };
        this.errors = {
            message: error.message,
            validationMessage: error.payload && error.payload.content ? error.payload.content : []
        };
        this.hasErrors = true;
    }
    onSuccess() {
        this.alert = {
            theme: AlertTheme.Success,
            useDefaultIcon: false,
            dismissible: true,
            autoTimeout: 10000
        };
        this.errors = { message: '', validationMessage: [] };
        this.hasErrors = false;
    }
};
__decorate([
    Inject(AuthServiceType)
], CommentAbstract.prototype, "authService", void 0);
__decorate([
    Inject(EventbusType)
], CommentAbstract.prototype, "eventBus", void 0);
CommentAbstract = __decorate([
    Component
], CommentAbstract);
export { CommentAbstract };
