import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { prepareImage } from '@d24/modules/front';
import { ModalType } from '@movecloser/front-core';
import { CommentRepositoryType } from '@content/contracts';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { RelatedServiceType } from '@service/related';
import { CommentAbstract } from '../CommentAbstract';
import { CommentActions } from './CommentActions';
import { CommentAvatar } from './CommentAvatar';
import { CommentForm } from '../CommentForm';
import { CommentMeta } from './CommentMeta';
/**
 * @author Sebastian Dziechciarz <sebastian.dziechciarz@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let Comment = class Comment extends CommentAbstract {
    constructor() {
        super(...arguments);
        this.avatar = null;
        this.hasCommentToAdd = false;
    }
    get canRemove() {
        // const userId = this.authService.getUserId()
        //
        // if (!userId) {
        //   return false
        // }
        //
        // return userId === this.comment.creator.id
        return false;
    }
    get canReplay() {
        return !this.comment.parentId;
    }
    get commentsOwnerId() {
        return this.parent.get('contentId');
    }
    /**
     * Determines wheter there are any replies to render.
     */
    get hasReplies() {
        return Array.isArray(this.comment.replies) && this.comment.replies.length !== 0;
    }
    canAdd() {
        return this.authService.check() && this.canReplay;
    }
    hasMoreReplies() {
        var _a;
        if (!this.comment.replies) {
            return false;
        }
        return this.comment.childrenCount > ((_a = this.comment.replies) === null || _a === void 0 ? void 0 : _a.length);
    }
    loadMoreReplies() {
        this.eventBus.emit('comment:loadReplies', this.comment);
    }
    addResponse(message) {
        if (!message) {
            return;
        }
        const payload = {
            content: message,
            parentId: this.comment.id,
            owner: this.commentsOwnerId,
            onSuccess: () => this.onSuccess(),
            onErrors: this.onErrors
        };
        this.commentRepository.addComment(payload).then(() => {
            if (payload && payload.onSuccess && typeof payload.onSuccess === 'function') {
                payload.onSuccess();
            }
        }).catch((e) => {
            if (payload && payload.onErrors && typeof payload.onErrors === 'function') {
                payload.onErrors(e);
            }
        });
    }
    /**
     * Handles `@click` event on the "Answer" `<D24Button>`.
     */
    onAnswerClick() {
        if (!this.canAdd()) {
            this.modalConnector.open(EModal.LogIn, {
                successCb: this.toggleCommentToAdd
            });
            return;
        }
        this.toggleCommentToAdd();
    }
    /**
     * Handles `@click` event on the "Bin" `<D24Button>`.
     */
    onBinClick() {
        this.eventBus.emit('comment:delete', this.comment);
    }
    toggleCommentToAdd() {
        this.hasCommentToAdd = true;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Comment.prototype, "comment", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Comment.prototype, "lockedCommenting", void 0);
__decorate([
    Prop({ type: Object, required: true })
], Comment.prototype, "parent", void 0);
__decorate([
    Inject(CommentRepositoryType)
], Comment.prototype, "commentRepository", void 0);
__decorate([
    Inject(ModalType)
], Comment.prototype, "modalConnector", void 0);
__decorate([
    Inject(RelatedServiceType)
], Comment.prototype, "relatedServices", void 0);
Comment = __decorate([
    Component({
        name: 'Comment',
        components: {
            CommentActions,
            CommentAvatar,
            CommentMeta,
            CommentForm
        },
        mounted() {
            if (!this.comment.creator) {
                return;
            }
            if (!this.comment.creator.avatar || !this.comment.creator.avatar.value) {
                return;
            }
            this.relatedServices.resolve(this.comment.creator.avatar).then(image => {
                this.avatar = prepareImage(image);
            });
        }
    })
], Comment);
export { Comment };
export default Comment;
