import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
import { Source } from './ArticleSource.contracts';
const PAP = require('@/assets/images/logotypes/pap.svg');
const MSPO = require('@/assets/images/logotypes/mspo.svg');
const sourceMap = {
    [Source.PAP]: {
        image: {
            src: PAP,
            alt: 'PAP',
            width: 42,
            height: 26
        },
        url: 'https://www.pap.pl/',
        newTab: true,
        useSite: false
    },
    [Source.PAPnD24]: {
        image: {
            src: PAP,
            alt: 'PAP',
            width: 42,
            height: 26
        },
        url: 'https://www.pap.pl/',
        newTab: true,
        useSite: true
    },
    [Source.MSPO]: {
        image: {
            src: MSPO,
            alt: 'MSPO',
            width: 347,
            height: 80
        },
        newTab: false,
        useSite: true
    }
};
/**
 * Component capable of rendering the article's source.
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let ArticleSource = class ArticleSource extends Vue {
    get sourceDetails() {
        return sourceMap[this.source];
    }
    get site() {
        return this.siteService.getActiveSite().name;
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    })
], ArticleSource.prototype, "source", void 0);
__decorate([
    Inject(SiteServiceType)
], ArticleSource.prototype, "siteService", void 0);
ArticleSource = __decorate([
    Component({ name: 'ArticleSource' })
], ArticleSource);
export { ArticleSource };
export default ArticleSource;
