import { __decorate } from "tslib";
import { AdBannerType, ModuleDriver } from '@d24/modules/front';
import { Component, Prop, Vue } from 'vue-property-decorator';
let StickySidebar = class StickySidebar extends Vue {
    constructor() {
        super(...arguments);
        this.allocatedHeight = 0;
        this.clientHeight = 0;
        this.singleStickyAdHeight = 1;
        this.interval = undefined;
        this.previousHeight = 0;
        this.rowGutter = 34;
        this.rowHeight = 150;
    }
    get minAdHeight() {
        const calculated = this.rowHeight * 2 + this.rowGutter;
        return calculated > 650 ? calculated : 650;
    }
    get modules() {
        return this.slotConfig.modules || [];
    }
    clearInterval() {
        clearInterval(this.interval);
    }
    resolveSidebarProperties() {
        this.clientHeight = this.$parent.$refs.articleContent.clientHeight;
        const adModulesCount = this.modules.reduce((count, m) => {
            if (m.driver === ModuleDriver.AdBanner && m.version === AdBannerType.Sticky) {
                count++;
            }
            return count;
        }, 0);
        if (!adModulesCount) {
            return;
        }
        const allocatedRows = this.modules.reduce((height, m) => {
            if (m.driver !== ModuleDriver.AdBanner || m.version !== AdBannerType.Sticky) {
                height += m.size.rowSpan;
            }
            return height;
        }, 0);
        this.allocatedHeight = (allocatedRows * this.rowHeight) + (allocatedRows * this.rowGutter);
        const calculatedHeight = Math.floor((this.clientHeight - this.allocatedHeight) / adModulesCount);
        this.singleStickyAdHeight = calculatedHeight > this.minAdHeight ? calculatedHeight : this.minAdHeight;
        document.documentElement.style.setProperty('--sticky-ad-height', `${this.singleStickyAdHeight}px`);
    }
    setInterval() {
        this.interval = setInterval(() => this.validateSidebar(), 300);
    }
    validateSidebar() {
        this.previousHeight = this.clientHeight;
        this.resolveSidebarProperties();
        if (Math.abs(this.previousHeight - this.clientHeight) < this.rowHeight) {
            this.clearInterval();
        }
    }
};
__decorate([
    Prop({ type: Object, required: true })
], StickySidebar.prototype, "slotConfig", void 0);
StickySidebar = __decorate([
    Component({
        name: 'StickySidebar',
        mounted() {
            this.$nextTick(() => this.resolveSidebarProperties());
            this.setInterval();
        },
        beforeDestroy() {
            this.clearInterval();
            if (typeof document !== 'undefined') {
                document.documentElement.style.removeProperty('--sticky-ad-height');
            }
        }
    })
], StickySidebar);
export { StickySidebar };
export default StickySidebar;
