import { __decorate } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AuthServiceType, ModalType } from '@movecloser/front-core';
import { AuthAction } from '@contract/auth';
import { Inject } from '@plugin/inversify';
import { CommentRepositoryType } from '../../contracts';
import { Comment } from '../Comments/Comment';
import { CommentAbstract } from './CommentAbstract';
import { CommentForm } from '../Comments/CommentForm';
import { defaultPerPage } from './Comments.config';
/**
 * @author Sebastian Dziechciarz <sebastian.dziechciarz@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let Comments = class Comments extends CommentAbstract {
    constructor() {
        super(...arguments);
        this.commentCount = 0;
        this.comments = [];
        this.isLoading = true;
        this.page = 1;
        this.showFullList = false;
        this.showFullListButton = false;
        this.commentsListHeight = 0;
    }
    get commentsOwnerId() {
        return this.parent.get('contentId');
    }
    get canLoadMore() {
        return this.comments.length < this.commentCount;
    }
    get lockedCommenting() {
        return this.parent.getOption('lockCommenting', false);
    }
    addComment(message) {
        const payload = {
            content: message,
            parentId: null,
            owner: this.commentsOwnerId
        };
        this.commentRepository.addComment(payload).then(() => {
            this.onSuccess();
            this.eventBus.emit('comments:add', {});
        }).catch((error) => {
            this.onErrors(error);
        });
    }
    openFullList() {
        this.eventBus.emit('comments:show-full', {});
        this.showFullList = true;
        this.showFullListButton = false;
    }
    canAdd() {
        return !this.isLoading;
    }
    async loadMore() {
        this.page += 1;
        await this.loadList(this.commentsOwnerId, this.page);
        this.$nextTick(() => {
            window.scroll({
                top: window.document.body.scrollHeight,
                behavior: 'smooth'
            });
        });
    }
    deleteComment(p) {
        if (!p.payload) {
            return;
        }
        this.commentRepository.deleteComment(p.payload).then(() => {
            // Handel delete
        }).catch((e) => {
            this.$logger(e, 'warn');
        });
    }
    async loadList(contentId, page, parentId) {
        this.isLoading = true;
        const authorizationHeader = this.authService.getAuthorizationHeader();
        const query = {
            page
        };
        if (parentId) {
            query.parentId = parentId;
        }
        try {
            const collection = await this.commentRepository.loadCollection(contentId, authorizationHeader, query);
            if (!parentId) {
                this.comments = [...this.comments, ...collection];
                // FIXME - for some reason this collection doesn't have meta has only _meta
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                this.commentCount = collection._meta.total | 0;
            }
            else {
                // Load replies to comment
                this.comments = this.comments.map(comment => {
                    if (comment.id === parentId) {
                        if (!comment.replies) {
                            comment = {
                                ...comment,
                                replies: [...collection]
                            };
                        }
                        else {
                            comment = {
                                ...comment,
                                replies: this.deleteDuplicates([...comment.replies, ...collection])
                            };
                        }
                    }
                    return comment;
                });
            }
        }
        catch (error) {
            this.$logger(error, 'warn');
        }
        this.isLoading = false;
    }
    onCommentsChange() {
        if (this.$refs.commentsList) {
            this.$nextTick(() => {
                this.commentsListHeight = this.$refs.commentsList.clientHeight;
                if (this.commentsListHeight > 400) {
                    this.showFullListButton = true;
                }
            });
        }
    }
    loadReplies(p) {
        if (!p.payload) {
            return;
        }
        // Method to count which page of replies should be loaded
        const page = p.payload.replies ? Math.floor(p.payload.replies.length / defaultPerPage) + 1 : 1;
        this.loadList(this.commentsOwnerId, page, p.payload.id);
    }
};
__decorate([
    Prop({
        type: Object,
        required: true
    })
], Comments.prototype, "parent", void 0);
__decorate([
    Inject(CommentRepositoryType)
], Comments.prototype, "commentRepository", void 0);
__decorate([
    Inject(AuthServiceType)
], Comments.prototype, "authService", void 0);
__decorate([
    Inject(ModalType)
], Comments.prototype, "modalConnector", void 0);
__decorate([
    Watch('comments')
], Comments.prototype, "onCommentsChange", null);
Comments = __decorate([
    Component({
        name: 'Comments',
        components: {
            CommentForm,
            Comment
        },
        async prefetch() {
            await this.loadList(this.commentsOwnerId, this.page);
        },
        created() {
            // this.eventBus.handle('comment:add', this.addResponse)
            this.eventBus.handle('comment:delete', this.deleteComment);
            this.eventBus.handle('comment:loadReplies', this.loadReplies);
            this.eventBus.handle('app:authentication', (payload) => {
                if (payload && payload.payload && payload.payload.action === AuthAction.Booted) {
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                }
            });
        }
    })
], Comments);
export { Comments };
export default Comments;
